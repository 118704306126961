import React, { useContext  } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import Sales from "./components/Dashboard/Sales";
import Products from "./components/Dashboard/Products";
import ProductDescription from "./components/Dashboard/description/ProductDescription";
import Lists from "./components/Dashboard/Lists";
import StockRegister from "./components/Dashboard/StockRegister";

const allroutes = [
  // Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "sales", component: <Sales /> },
    { url: "products", component: <Products /> },
    { url: "product", component: <ProductDescription /> },
    { url: "stock-register", component: <StockRegister /> },
    { url: "lists", component: <Lists /> },
];

const Markup = () => {   
  
  function NotFound(){    
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]    
      
    if(url.indexOf(path) <= 0){     
      return <Error404 />
    }
  }
   
    return (
      <>
          <Routes>              
              <Route path='/page-lock-screen' element= {<LockScreen />} />
              <Route path='/page-error-400' element={<Error400/>} />            
              <Route path='/page-error-403' element={<Error403/>} />
              <Route path='/page-error-404' element={<Error404/>} />
              <Route path='/page-error-500' element={<Error500/>} />
              <Route path='/page-error-503' element={<Error503/>} />     
              <Route  element={<MainLayout />} > 
                  {allroutes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`${data.url}`}
                      element={data.component}
                    />
                    ))}
              </Route>                
              <Route path='*' element={<NotFound/>} />     
          </Routes>     
          <ScrollToTop />          
      </>
    );       
};


  function MainLayout(){      
    const sideMenu = useSelector(state => state.sideMenu);
    return (
      <div id="main-wrapper" className={`show ${ sideMenu ? "menu-toggle" : ""}`}>  
          <Nav />
          <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>          
            <Outlet />   
          </div>
        <Footer />
      </div>
    )
  };



export default Markup;
