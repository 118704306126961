import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-scroll";
import OnlyTable from "./OnlyTable";

const TableItems = (props) => {

    return (
        <div className="col-xl-12">
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects manage-client">
                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                            <h4 className="heading mb-0">{props.title}</h4>
                            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                {
                                    props.button ? ( props.button() ) : (<></>)
                                }
                                {
                                    props.creator ? (
                                        <div>
                                            <Link to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
                                                  onClick={()=>props.creator.current.showModal()}
                                            >+ {props.creatorDialog}</Link> {" "}
                                        </div>
                                    ) : (<></>)
                                }
                            </div>
                        </div>
                        <OnlyTable
                            tableColumns={props.tableColumns}
                            data={props.data}
                            dataItem={props.dataItem}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}
export default TableItems;