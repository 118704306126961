import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {useNavigate} from "react-router-dom";
import CreateItemDialog from "./create/CreateItemDialog";
import {getList} from "./utils/Utils";
import ListItemTable from "./objects/ListItemTable";

const Lists = () => {
	const navigate = useNavigate();

	const categoriesD = useRef();
	const essencesD = useRef();

	const [categories, setCategories] = useState([]);
	const [essences, setEssences] = useState([]);

	useEffect(() => {
		getList("categories", (items) => {  setCategories(items)})
		getList("essences", (items) => {  setEssences(items)})

	}, []);

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className="col-xl-9 wid-100">
					<div className="row">
						<div className="col-xl-4">
							<ListItemTable modal={categoriesD} data={categories} list="categories" title="Categorias"/>
						</div>
						<div className="col-xl-4">
							<ListItemTable modal={essencesD} data={essences} list="essences" title="Esencias"/>
						</div>
					</div>
				</div>
			</div>

			<CreateItemDialog ref={categoriesD} Title="Crear Categoria" list="categories"/>
			<CreateItemDialog ref={essencesD} Title="Crear Esencia" list="essences"/>
		</>
	);

}
export default Lists;