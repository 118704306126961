import React, {useContext, useEffect, useRef, useReducer, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import MainPagetitle from "../../../layouts/MainPagetitle";
import Variant from "../items/Variant";
import CreateProductDialog from "../create/CreateProductDialog";
import CreateVariantDialog from "../create/CreateVariantDialog";
import TableItems from "../objects/TableItems";
import CountUp from "react-countup";
import {host} from "../utils/Constants";

const ProductDescription = () => {
    const dialog = useRef();
    const dialogProd = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const [product, setProduct] = useState({});

    const [variants, setVariants] = useState([]);

    const [stock, setStock] = useState(0);
    const [sales, setSales] = useState(0);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    let id = searchParams.get("id")

    const init =  false;

    const reducer = (state, active) =>{
        switch(active.type){
            case 'largeModal'	:
                return { ...state, largeModal: !state.largeModal}
            default :
                return state;
        }
    }

    const [state ,dispatch] = useReducer(reducer, init);

    useEffect(() => {

        fetch(host + "product/" + id + "/")
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let variants = Object.values(data.variants)

                setProduct({
                    "id": data.product._id,
                    "name": data.product.name,
                    "price": data.product.price,
                    "marca": data.product.marca,
                    "category": data.product.category,
                })

                let variantsArray = []
                let stockControl = 0
                let salesControl = 0

                for (let variant in variants) {
                    variantsArray.push(
                        {
                            "_id": variants[variant]._id,
                            "name": variants[variant].name,
                            "stock": variants[variant].stock,
                            "sales": variants[variant].sales,
                            "product_id": variants[variant].product_id
                        }
                    )
                    salesControl += variants[variant].sales
                    stockControl += variants[variant].stock
                }

                setStock(stockControl)
                setSales(salesControl)

                setVariants(variantsArray)
            })
            .catch(function(error) {
                console.log(error);
            });

        setData([
            {title: "Categoria", value: product.category ? product.category : "Sin categoria", colorstyle: 'success'},
            {title: "Variantes", value: variants.length, colorstyle: 'primary'},
            {title: "Stock", value: stock, colorstyle: 'purple'},
            {title: "Ventas", value: sales, colorstyle: 'purple'},
        ])

    }, []);

    const deleteProduct = () => {
        if (window.confirm("Quiere eliminar este producto ?")) {
            let url = host + "product/" + id + "/?"
            fetch(url, {method: 'DELETE'})
                .then(function(response) {
                    navigate("/products")
                })
                .catch(function(error) {
                    console.log(error);
                });

        }
    }

    return (
        <>
            <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects manage-client">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 style={{fontSize: "30px"}} className="heading mb-0">{product.name}</h4>
                                        <div>
                                            <button onClick={()=>dialog.current.showModal()} style={{float: "right"}} type="button" className="btn btn-primary p-2">Crear variante</button>
                                            <button onClick={() => dialogProd.current.showModal()} style={{float: "right", marginRight: "20px"}} type="button" className="btn btn-primary p-2">Editar</button>
                                            <button onClick={() => deleteProduct()} style={{float: "right", marginRight: "20px"}} type="button" className="btn btn-primary p-2">Borrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{ product.category ? product.category : "Sin Categoria" }</h6>
                                        <h6>Categoria</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{variants.length}</h6>
                                        <h6>Variantes</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{stock}</h6>
                                        <h6>Stock</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-6">
                        <div className="card box-hover">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="total-projects ms-3">
                                        <h6 className={`project-counter count text-${data.colorstyle}`}>{ sales ? sales : 0}</h6>
                                        <h6>Ventas</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <TableItems
                    title="Variantes"
                    creator={dialog}
                    creatorDialog="Crear variante"
                    tableColumns={["Nombre", "Stock", "Ventas", "Acciones"]}
                    data={variants}
                    dataItem={ (data) => { return <Variant data={data}/> } }
                />

            </div>


            <CreateProductDialog
                ref={dialogProd}
                Title="Editar producto"
                id={id}
                edit={true}
            />

            <CreateVariantDialog
                ref={dialog}
                Title="Crear variante"
                edit={false}
            />
        </>
    );

}
export default ProductDescription;