import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import {getList, makeSelectList} from "../utils/Utils";
import Select from "react-select";
import {host} from "../utils/Constants";

const CreateClientDialog =forwardRef((props, ref) => {
    const [addEmploye , setAddEmploye] = useState(false);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    const [customName , setCustomName] = useState(false)
    const [nombres , setNombres] = useState([])
    const [categories , setCategories] = useState([])

    const [nombre, setNombre] = useState(null);
    const [category, setCategory] = useState(null);

    useEffect(() => {
        getList("categories", (items) => {setCategories(makeSelectList(items))} )
        getList("nombres", (items) => {  setNombres(makeSelectList(items))} )
    }, []);

    const handleNombre = (e) => {
        setNombre(e.value)
    }

    const handleCategories = (e) => {
        setCategory(e.value)
    }

    const action = () => {
        if (props.edit) {
            editProduct(props.id)
        } else {
            createProduct()
        }
    }

    const createProduct = () => {
        let url = host + "product/?"

        if (document.getElementById("custom-name").checked) {
            url += "name=" + nombre + "&"
        } else {
            url += "name=" + document.getElementById("name").value + "&"
        }
        url += "category=" + category + "&"
        url += "wholesaler_price=" + document.getElementById("wholesaler_price").value + "&"
        url += "retail_price=" + document.getElementById("retail_price").value

        console.log(url)

        fetch(url, {method: 'POST'})
            .then(function(response) {
                if (response.ok) {return response.json();}
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                navigate("/product?id=" + data.inserted_id)
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    const editProduct = (id) => {
        let url = host + "product/" + id + "/?"

        if (document.getElementById("custom-name").checked) {
            if (nombre !== null && nombre !== "") {
                url += "name=" + nombre + "&"
            }
        } else {
            let name = document.getElementById("name").value
            if (name !== null && name !== "") {
                url += "name=" + name + "&"
            }
        }

        if (category !== null && category !== "") {
            url += "category=" + category + "&"
        }

        let wholesaler_price = document.getElementById("wholesaler_price").value
        if (wholesaler_price !== null && wholesaler_price !== "") {
            url += "wholesaler_price=" + wholesaler_price + "&"
        }
        let retail_price = document.getElementById("retail_price").value
        if (retail_price !== null && retail_price !== "") {
            url += "retail_price=" + retail_price + "&"
        }

        if (url !== host + "product/" + id + "/?") {
            fetch(url, {method: 'PUT'})
                .then(function(response) {
                    window.location.reload();
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }

    const handleCustomname = (e) => {
        setCustomName(document.getElementById("custom-name").checked)
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-3" style={{display: customName === true ? "block" : "none"}}>
                                <label className="form-label">Nombre Default <span className="text-danger">*</span></label>
                                <Select id="default-name" defaultValue="" options={nombres} onChange={handleNombre}/>
                            </div>
                            <div className="col-xl-6 mb-3" style={{display: customName === true ? "none" : "block"}}>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Nombre</label>
                                <input type="text" className="form-control" id="name" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Categoria <span className="text-danger">*</span></label>
                                <Select id="category" defaultValue="" options={categories} onChange={handleCategories}/>
                            </div>
                            <div className="form-check custom-checkbox mb-3 checkbox-danger" style={{marginLeft: "10px"}}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="custom-name"
                                    onChange={handleCustomname}
                                />
                                <label className="form-check-label">Nombre default</label>
                            </div>
                            <div/>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Precio Mayorista</label>
                                <input type="text" className="form-control" id="wholesaler_price" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Precio Minorista</label>
                                <input type="text" className="form-control" id="retail_price" placeholder="" />
                            </div>

                        </div>
                        <div>
                            <button onClick={() => { action() }} className="btn btn-primary me-1">Aceptar</button>
                            <Link to={"#"} onClick={()=> { setAddEmploye(false)}} className="btn btn-danger light ms-1">Cancelar</Link>
                        </div>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default CreateClientDialog;