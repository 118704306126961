import {host} from "./Constants";

export const getList = (list, setItems) => {
    fetch(host + "lists/" + list)
        .then(function(response) {
            if (response.ok) {return response.json();}
            throw new Error("Error en la solicitud HTTP");
        })
        .then(function(data) {
            let items = []
            for(let i in data.list){
                let jsonData = Object.values(data.list[i])
                items.push({
                    "id": jsonData[0],
                    "name": jsonData[1],
                })
            }
            setItems(items)
        })
        .catch(function(error) {
            console.log(error);
        });
}

export const makeSelectList = (items) => {
    return items.map(item => ({ label: item.name, value: item.name }));
}