import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {Card, Col, Nav, Tab} from "react-bootstrap";
import CountUp from "react-countup";
import TableItems from "./objects/TableItems";
import {getList} from "./utils/Utils";
import Product from "./items/Product";
import ProductCategory from "./items/ProductCategory";
import OnlyTable from "./objects/OnlyTable";
import {host} from "./utils/Constants";

const Home = () => {

	const [data, setData] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {

		fetch(host + "information/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let jsonData = Object.values(data)

				let dic = [
					{number: jsonData[1], countText: 'success', title:'Ventas'},
					{number: jsonData[2], countText: 'warning', title:'Usuarios'},
					{number: jsonData[0], countText: 'primary', title:'Productos'},
					{number: jsonData[3], countText: 'purple', title:'Variantes'},
				]

				setData(dic)
			})
			.catch(function(error) {
				console.log(error);
			});


		getList("categories", (items) => {
			setCategories([])
			for (let item in items) {
				fetch(host + "product/byCategory/" + items[item].name + "/")
					.then(function(response) {
						if (response.ok) {return response.json();}
						throw new Error("Error en la solicitud HTTP");
					})
					.then(function(data) {
						let jsonData = Object.values(data)

						let stockValue = 0
						let salesValue = 0
						for (let product in jsonData) {
							for (let variant in jsonData[product].variants) {
								stockValue += jsonData[product].variants[variant].stock
								salesValue += jsonData[product].variants[variant].sales
							}

						}

						console.log(stockValue)
						console.log(salesValue)

						let dic = [
							{
								"category": items[item].name,
								"stock": stockValue,
								"sales": salesValue,
								"data": jsonData,
							}
						]

						dic = [...dic, ...categories];

						setCategories(dic)
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		})


	}, []);



	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
			<div className="container-fluid">
				<Col xl="12">
					<Tab.Container defaultActiveKey="zone">

						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row task">
										{data.map((item, index)=>(
											<div className="col-xl-2 col-sm-4 col-6" key={index}>
												<div className="task-summary">
													<div className="d-flex align-items-baseline">
														<CountUp className={`mb-0 fs-28 fw-bold me-2 text-${item.countText}`}  end={item.number} duration={'5'} />
														<h6 className='mb-0'>{item.title}</h6>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>

						<Card  name="badges-light" className="dz-card">
							<Card.Header className="d-flex justify-content-between flex-wrap">
								<div>
									<Card.Title>Estadisticas</Card.Title>
								</div>
							</Card.Header>
							<Card.Body>
								<div className="container-fluid">
									<div className="row">
										{ categories.map((item, ind)=>(
											<div className="col-xl-5 col-sm-6" key={ind}>
												<div className="card box-hover">
													<div className="card-header">
														<h5 className="mb-0"># {item.category}</h5>
														<div>
															<h7 className="mb-0">Ventas: {item.sales ? item.sales : 0}</h7><br/>
															<h7 className="mb-0">Stock: {item.stock}</h7>
														</div>
													</div>
													<div className="card-body">

														<table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0" style={{padding: "0"}}>
															<thead>
															<tr >
																<th style={{fontSize: "12px"}}>Nombre</th>
																<th style={{fontSize: "12px"}}>Stock</th>
																<th style={{fontSize: "12px"}}>Ventas</th>
															</tr>
															</thead>
															<tbody id="table">
																{ item.data.map((data) => {return <ProductCategory data={data}/>} ) }
															</tbody>

														</table>

													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</div>
		</>
	);

}
export default Home;