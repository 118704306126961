import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import axios from "axios";
import {host} from "../jsx/components/Dashboard/utils/Constants";

export function login(email, password) {
    return axios.get(host + "users/?email=" + email + "&password=" + password)
}

export function formatError(errorResponse) {
    switch (errorResponse.message) {
        case 'Item not found':
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'Wrong password':
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        default:
            return errorResponse.message;
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}
export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}