import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import Select from "react-select";
import {getList, makeSelectList} from "../utils/Utils";
import {host} from "../utils/Constants";

const CreateVariantDialog =forwardRef((props, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [addEmploye , setAddEmploye] = useState(false);

    const [essences, setEssences] = useState(null);

    useEffect(() => {
        getList("essences", (items) => {  setEssences(makeSelectList(items))})
    }, []);

    const [stock , setStock] = useState([
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" }

    ]);

    const [essence, setEssence] = useState(null);

    const [customNumber , setCustomNumber] = useState(false)
    const [stockValue , setStockValue] = useState(0)

    const handleStock = (e) => {
        setStockValue(e.value)
    }

    const handleCustomNumber = (e) => {
        setCustomNumber(document.getElementById("custom-number").checked)
    }

    const handleEssence = (e) => {
        setEssence(e.value)
    }

    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));

    let id = searchParams.get("id")

    const action = () => {
        if (props.edit) {
            editVariant(props.id)
        } else {
            createVariant()
        }
    }

    const editVariant = (id) => {
        let url = host + "product/variant/" + id + "/?"

        if (essence !== null && essence !== "") {
            url += "name=" + essence + "&"
        }

        if (document.getElementById("custom-number").checked) {
            let number = document.getElementById("number").value
            if (number !== null && number !== "") {
                url += "stock=" + number
            }
        } else {
            url += "stock=" + stockValue
        }

        console.log(url)

        if (url !== host + "product/variant/" + id + "/?") {
            fetch(url, {method: 'PUT'})
                .then(function(response) {
                    window.location.reload();
                    setAddEmploye(false)
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }

    const createVariant = () => {
        if (essence !== null && essence !== "") {
            let url = host + "product/variant/?"
            url += "name=" + essence + "&"
            url += "product_id=" + id + "&"

            if (document.getElementById("custom-number").checked) {
                let number = document.getElementById("number").value
                if (number !== null && number !== "") {
                    url += "stock=" + number
                }
            } else {
                url += "stock=" + stockValue
            }

            fetch(url, {method: 'POST'})
                .then(function(response) {
                    window.location.reload();
                    setAddEmploye(false)
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {
            window.alert("Complete todos los campos")
        }
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-3">
                                <label className="form-label">Esencia <span className="text-danger">*</span></label>
                                <Select id="color" defaultValue="" options={essences} onChange={handleEssence}/>
                            </div>

                            <div className="col-xl-6 mb-3" style={{display: customNumber === true ? "none" : "block"}}>
                                <label className="form-label">Stock <span className="text-danger">*</span></label>
                                <Select id="default-number" defaultValue="" options={stock} onChange={handleStock}/>
                            </div>
                            <div className="col-xl-6 mb-3" style={{display: customNumber === true ? "block" : "none"}}>
                                <label htmlFor="exampleFormControlInput1" className="form-label">Stock</label>
                                <input type="text" className="form-control" id="number" placeholder="" />
                            </div>
                            <div className="form-check custom-checkbox mb-3 checkbox-danger" style={{marginLeft: "10px"}}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="custom-number"
                                    onChange={handleCustomNumber}
                                />
                                <label className="form-check-label">Otro numero</label>
                            </div>
                        </div>
                        <div>
                            <button onClick={() => { action() }} className="btn btn-primary me-1">Aceptar</button>
                            <Link to={"#"} onClick={()=> { setAddEmploye(false)}} className="btn btn-danger light ms-1">Cancelar</Link>
                        </div>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default CreateVariantDialog;