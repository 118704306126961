import { SVGICON } from "../../constant/theme";

export const MenuList = [
    //Content
    {
        title: 'Stock Control',
        classsChange: 'menu-title'
    },
    //Dashboard
    {
        title: 'Dashboard',
        iconStyle: SVGICON.Home,
        to: '/dashboard',
    },
    {
        title: 'Ventas',
        iconStyle: SVGICON.Finance,
        to: '/sales',
    },
    {
        title: 'Productos',
        iconStyle: SVGICON.Pages,
        to: '/products',
    },
    {
        title: 'Cargar stock',
        iconStyle: SVGICON.EditPrimary,
        to: '/stock-register',
    },
    {
        title: 'Listas',
        iconStyle: SVGICON.Blog,
        to: '/lists',
    },
    
]