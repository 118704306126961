import React, {useContext, useEffect, useRef, useState} from 'react';

const Sale = (data) => {
	const dialog = useRef();

	const [stockCounter, setstockCounter] = useState([]);

	useEffect(() => {
		let products = Object.values(data.data.sales)
		let stockCounterValue = 0
		for (let index in products) {
			stockCounterValue += products[index].stock
		}
		setstockCounter(stockCounterValue)

	}, []);



	return (
		<>
			<tr>
				<td><span>{data.data._id}</span></td>
				<td><span>{stockCounter}</span></td>
				<td><span>{data.data.date}</span></td>
				<td><span>{data.data.total}</span></td>
			</tr>
		</>
	);

}
export default Sale;