import React, {useContext, useEffect, useReducer, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import CreateVariantDialog from "../create/CreateVariantDialog";
import {host} from "../utils/Constants";
import AddStockVariantDialog from "../create/AddStockVariantDialog";

const Variant = (data) => {
    const navigate = useNavigate();
    const dialog = useRef();
    const dialogAdd = useRef();

    const deleteVariant = () => {
        if (window.confirm("Quiere eliminar esta variante ?")) {
            let url = host + "product/variant/" + data.data._id + "/"
            fetch(url, {method: 'DELETE'})
                .then(function(response) {
                    window.location.reload();
                })
                .catch(function(error) {
                    console.log(error);
                });

        }
    }

    return (
        <>
            <tr>
                <td><span>{data.data.name}</span></td>
                <td><span>{data.data.stock}</span></td>
                <td><span>{data.data.sales}</span></td>
                <td>
                    <button onClick={() => dialogAdd.current.showModal()} style={{marginTop: "10px", marginLeft: "20px"}} type="button" className="btn btn-primary p-2">Agregar Stock</button>
                    <button onClick={() => dialog.current.showModal()} style={{marginTop: "10px", marginLeft: "20px"}} type="button" className="btn btn-primary p-2">Editar</button>
                    <button onClick={() => deleteVariant()} style={{marginTop: "10px", marginLeft: "20px"}} type="button" className="btn btn-primary p-2">Borrar</button>
                </td>
            </tr>

            <AddStockVariantDialog
                ref={dialogAdd}
                Title="Agregar Stock"
                id={data.data._id}
            />

            <CreateVariantDialog
                ref={dialog}
                Title="Editar variante"
                id={data.data._id}
                edit={true}
            />
        </>
    );

}
export default Variant;