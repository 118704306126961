import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {useNavigate} from "react-router-dom";
import FindTable from "./objects/FindTable";
import {host} from "./utils/Constants";
import FindableVariableForStock from "./items/FindableVariableForStock";

const StockRegister = () => {
    const navigate = useNavigate();

    const formatFindData = (data) => {
        let items = []
        for(let i in data.variants){
            items.push(data.variants[i])
        }
        return items
    }

    return (
        <>
            <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

            <div className='container-fluid'>
                <div className='row'>
                    <FindTable
                        tableColumns={["Nombre", "Stock", "", "Sumar", "Accion"]}
                        formatData={formatFindData}
                        dataItem={ (data) => { return <FindableVariableForStock data={data}/> } }
                        url={host + "product/variant/name/"}
                    />
                </div>
            </div>
        </>
    );

}
export default StockRegister;