import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {useNavigate} from "react-router-dom";
import Product from "./items/Product";
import CreateProductDialog from "./create/CreateProductDialog";
import TableItems from "./objects/TableItems";
import FindTable from "./objects/FindTable";
import {host} from "./utils/Constants";

const Home = () => {
	const navigate = useNavigate();

	const dialog = useRef();

	const [items, setItems] = useState([]);

	const productTable = ["Nombre", "Minorista", "Mayorista", "Stock", "Variantes"]

	useEffect(() => {
		fetch(host + "product/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				setItems(formatData(data))
			})
			.catch(function(error) {
				console.log(error);
			});

	}, []);

	const formatData = (data) => {
		let items = []
		for(let i in data.products){
			let jsonData = data.products[i]

			let stock = 0
			for (let i = 0; i < jsonData["variants"].length; i++) {
				stock += jsonData["variants"].stock
			}

			items.push({
				"_id": jsonData["product"]._id,
				"name": jsonData["product"].name,
				"wholesaler_price": jsonData["product"].wholesaler_price,
				"retail_price": jsonData["product"].retail_price,
				"stock": stock,
				"variants": jsonData["variants"].length
			})
		}
		return items
	}
	const formatFindData = (data) => {
		let items = []
		for(let i in data.products){
			items.push(data.products[i])
		}
		return items
	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<FindTable
						tableColumns={productTable}
						formatData={formatFindData}
						dataItem={ (data) => {
							console.log(data)
							return <Product data={data}/>
						} }
						url={host + "product/find/"}
					/>

					<TableItems
						title="Productos"
						creator={dialog}
						creatorDialog="Crear producto"
						tableColumns={productTable}
						data={items}
						dataItem={ (data) => { return <Product data={data}/> } }
						button={() => { return (
							<a type="button" className="btn btn-primary light btn-sm me-1" href={host + "export/stock"}>
								Exportar
							</a>
						)
						}}
					/>
				</div>
			</div>

			<CreateProductDialog ref={dialog} edit={false} Title="Crear producto"/>
		</>
	);

}
export default Home;