import React, {useContext, useEffect, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import Sale from "./items/Sale";
import TableItems from "./objects/TableItems";
import {host} from "./utils/Constants";

const Home = () => {

	const [items, setItems] = useState([]);

	useEffect(() => {

		fetch(host + "sales/")
			.then(function(response) {
				if (response.ok) {return response.json();}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let items = []
				for(let i in data.sales){
					let jsonData = Object.values(data.sales[i])

					console.log(jsonData)

					items.push({
						"_id": jsonData[0],
						"date": jsonData[2],
						"sales": jsonData[4],
						"total": jsonData[5]
					})
				}
				setItems(items)
			})
			.catch(function(error) {
				console.log(error);
			});

	}, []);



	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className='container-fluid'>
				<div className='row'>
					<TableItems
						title="Ventas"
						tableColumns={["Fact. ID", "Productos", "Fecha", "Total"]}
						data={items}
						dataItem={ (data) => { return <Sale data={data}/> } }
						button={() => { return (
							<a type="button" className="btn btn-primary light btn-sm me-1" href={host + "export/sales"}>
								Exportar
							</a>
						)
						}}
					/>
				</div>
			</div>
		</>
	);

}
export default Home;