import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

const ProductCategory = (props) => {
	const navigate = useNavigate();

	const [stock, setStock] = useState([]);
	const [sales, setSales] = useState([]);

	const show = () => {
		navigate("/product/?id=" + props.data.product._id)
	}

	useEffect(() => {

		let stockValue = 0
		let salesValue = 0
		for (let variant in props.data.variants) {
			stockValue += props.data.variants[variant].stock
			salesValue += props.data.variants[variant].sales
		}
		setStock(stockValue)
		setSales(salesValue)

	}, []);

	return (
		<>
			<tr onClick={() => show()}>
				<td><span style={{fontSize: "12px"}}>{props.data.product.name}</span></td>
				<td><span style={{fontSize: "12px"}}>{stock}</span></td>
				<td><span style={{fontSize: "12px"}}>{sales ? sales : 0}</span></td>
			</tr>
		</>
	);

}
export default ProductCategory;