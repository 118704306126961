import React, {useEffect, useRef} from 'react';
import {Link} from "react-scroll";
import ListItem from "../items/ListItem";

const ListItemTable = (props) => {

	return (
		<div className="card">
			<div className="card-header border-0">
				<h4 className="heading mb-0">{props.title}</h4>
				<div>
					<Link to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
						  onClick={()=>props.modal.current.showModal()}
					>+ Agregar</Link> {" "}
				</div>
			</div>
			<div className="card-body p-0">
				<div className="dt-do-bx">
					<div className="draggable-zone dropzoneContainer to-dodroup dz-scroll">
						<table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
							<thead style={{display: "none"}}>
							<tr>
								<th></th>
								<th></th>
							</tr>
							</thead>
							<tbody>
							{
								props.data.map((item) => {
									return (<ListItem data={item} list={props.list}/>);
								})
							}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);

}
export default ListItemTable;