import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

const Product = (data) => {
	const navigate = useNavigate();

	const show = () => {
		navigate("/product/?id=" + data.data._id)
	}

	return (
		<>
			<tr onClick={() => show()}>
				<td><span>{data.data.name}</span></td>
				<td><span>{data.data.retail_price}</span></td>
				<td><span>{data.data.wholesaler_price}</span></td>
				<td><span>{data.data.stock}</span></td>
				<td><span>{data.data.variants}</span></td>
			</tr>
		</>
	);

}
export default Product;