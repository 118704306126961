import React from 'react';
//import { useNavigate } from "react-router-dom";


import {
    formatError,
    login
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';


export function Logout(navigate) {
	localStorage.removeItem('userDetails');
    navigate('/login');
	//history.push('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return  (dispatch) => {
         login(email, password)
            .then((response) => {
                sessionStorage.setItem("userId", response.id)
                sessionStorage.setItem("email", email)
                dispatch(loginConfirmedAction());
                navigate('/dashboard');
            })
            .catch((error) => {


                const errorMessage = formatError(error);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction() {
    return {
        type: LOGIN_FAILED_ACTION,
    };
}

export function loginConfirmedAction() {
    return {
        type: LOGIN_CONFIRMED_ACTION,
    };
}

export function confirmedSignupAction() {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {
    return {        
      type: 'NAVTOGGLE',
    };
};