import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-scroll";

const OnlyTable = (props) => {

    const [currentPage , setCurrentPage] = useState(1);

    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = props.data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(props.data.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)

    function prePage (){
        if(currentPage !== 1){
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage (id){
        setCurrentPage(id);
    }
    function nextPage (){
        if(currentPage !== npage){
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <div id="manage-tblwrapper" className="dataTables_wrapper no-footer">
            <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                <thead>
                <tr>
                    { props.tableColumns.map((data) => {return <th>{data}</th>}) }
                </tr>
                </thead>
                <tbody id="table">
                    { records.map((data) => {return props.dataItem(data)}) }
                </tbody>

            </table>
            <div className="d-sm-flex text-center justify-content-between align-items-center">
                <div className='dataTables_info'>
                    Viendo del {lastIndex-recordsPage + 1} al{" "}
                    {props.data.length < lastIndex ? props.data.length : lastIndex}
                    {" "}de {props.data.length} productos
                </div>
                <div className="dataTables_paginate paging_simple_numbers justify-content-center" id="example2_paginate">
                    <Link className="paginate_button previous disabled" to="#" onClick={prePage}>
                        <i className="fa-solid fa-angle-left" />
                    </Link>
                    <span>
                        {number.map((n , i )=>(
                            <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i} onClick={()=>changeCPage(n)}>{n}</Link>
                        ))}
                    </span>
                    <Link className="paginate_button next" to="#" onClick={nextPage}>
                        <i className="fa-solid fa-angle-right" />
                    </Link>
                </div>
            </div>
        </div>
    );

}
export default OnlyTable;