import {useNavigate} from "react-router-dom";
import React, {useRef} from "react";
import {host} from "../utils/Constants";

const FindableVariableForStock = (data) => {
    const navigate = useNavigate();

    const edit = () => {
        let url = host + "product/variant/" + data.data._id + "/?"

        let stock = document.getElementById("stock" + data.data._id).value
        if (stock !== null && stock !== "") {
            let newStock = parseInt(data.data.stock) + parseInt(stock)
            url += "stock=" + newStock + "&"
        }

        if (url !== host + "product/variant/" + data.data._id + "/?") {
            fetch(url, {method: 'PUT'})
                .then(function(response) {
                    window.location.reload();
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }

    return (
        <>
            <tr>
                <td><span>{data.data.name}</span></td>
                <td><span>{data.data.stock}</span></td>
                <td><span>+</span></td>
                <td><span><input type="number" className="form-control" id={"stock" + data.data._id} placeholder={0} /></span></td>
                <td>
                    <button onClick={() => edit()} style={{marginTop: "10px", marginLeft: "20px"}} type="button" className="btn btn-primary p-2">Sumar</button>
                </td>
            </tr>
        </>
    );

}
export default FindableVariableForStock;